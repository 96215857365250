import { Content } from "./content";

export const MinistriesDetails = [
  {
    Id: "1",
    Title: "Ambassadors For Christ",
    PageName: "ambassadors-for-christ",
    Description:
      "Meets Every other Friday. Please contact Emad E. for more details.",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    Image: "/assets/images/ministries/Ambassadors For Christ.jpg",
    DetailPagePath: "/ambassadors-for-christ",
  },
  {
    Id: "2",
    Title: "Sunday School Curriculum",
    PageName: "sunday-school-curriculum",
    Description: `<div class="entry-content">
        <h3 style="margin: 0px 0px 20px; padding: 0px; outline: 0px; border: 0px currentColor; color: #279fdb; text-transform: none; line-height: normal; text-indent: 0px; letter-spacing: normal; clear: both; font-family: 'Source Sans Pro', sans-serif; font-size: 22px; font-style: normal; font-weight: normal; word-spacing: 0px; vertical-align: baseline; white-space: normal; orphans: 2; widows: 2; background-color: #ffffff; font-variant-ligatures: normal; font-variant-caps: normal; -webkit-text-stroke-width: 0px;">Curriculum</h3>
<p style="margin: 0px 0px 0.5em; padding: 0px; outline: 0px; border: 0px currentColor; color: #333333; text-transform: none; line-height: 21px; text-indent: 0px; letter-spacing: normal; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-style: normal; font-weight: normal; word-spacing: 0px; vertical-align: baseline; white-space: normal; orphans: 2; widows: 2; background-color: #ffffff; font-variant-ligatures: normal; font-variant-caps: normal; -webkit-text-stroke-width: 0px;">The following materials are offered by the Southern Diocese of the Coptic Orthodox Church,&nbsp;North America. All materials are developed under the supervision of H. G. Bishop Yousef and are frequently reviewed and updated by the dioceses servants and after his review. At St.&nbsp;George Coptic Orthodox Church of Monee, IL&nbsp;we will follow this curriculum.</p>
<ul style="list-style: square; margin: 0px 0px 1.5em 2.5em; padding: 0px; outline: 0px; border: 0px currentColor; color: #333333; text-transform: none; text-indent: 0px; letter-spacing: normal; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-style: normal; font-weight: normal; word-spacing: 0px; vertical-align: baseline; white-space: normal; orphans: 2; widows: 2; background-color: #ffffff; font-variant-ligatures: normal; font-variant-caps: normal; -webkit-text-stroke-width: 0px;">
<li><a href="http://www.suscopts.org/ssc/" target="_blank" rel="noreferrer">Home</a></li>
<li><a href="http://www.suscopts.org/ssc/introduction/">Introduction</a>
<ul>
<li><a href="http://suscopts.org/ssc/wp-content/uploads/2014/12/SSC.ppt">Curriculum Presentation</a></li>
<li><a href="http://www.suscopts.org/ssc/users-guidelines/" target="_blank" rel="noreferrer">User’s Guidelines</a></li>
<li><a href="http://suscopts.org/ssc/wp-content/uploads/2014/12/Main.pdf">Schedule for All Grades</a></li>
</ul>
</li>
</ul>
<ul style="list-style: square; margin: 0px 0px 1.5em 2.5em; padding: 0px; outline: 0px; border: 0px currentColor; color: #333333; text-transform: none; text-indent: 0px; letter-spacing: normal; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-style: normal; font-weight: normal; word-spacing: 0px; vertical-align: baseline; white-space: normal; orphans: 2; widows: 2; background-color: #ffffff; font-variant-ligatures: normal; font-variant-caps: normal; -webkit-text-stroke-width: 0px;">
<li><a href="http://www.suscopts.org/ssc/">Curriculum</a></li>
</ul>
<ul style="list-style: square; margin: 0px 0px 1.5em 2.5em; padding: 0px; outline: 0px; border: 0px currentColor; color: #333333; text-transform: none; text-indent: 0px; letter-spacing: normal; font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-style: normal; font-weight: normal; word-spacing: 0px; vertical-align: baseline; white-space: normal; orphans: 2; widows: 2; background-color: #ffffff; font-variant-ligatures: normal; font-variant-caps: normal; -webkit-text-stroke-width: 0px;">
<ul>
<li><a href="http://www.suscopts.org/ssc/pre-school/">Pre-School</a></li>
<li><a href="http://www.suscopts.org/ssc/kindergarten/" target="_blank" rel="noreferrer">Kindergarten</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-1/" target="_blank" rel="noreferrer">Grade 1</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-2/" target="_blank" rel="noreferrer">Grade 2</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-3/" target="_blank" rel="noreferrer">Grade 3</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-4/" target="_blank" rel="noreferrer">Grade 4</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-5/" target="_blank" rel="noreferrer">Grade 5</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-6/" target="_blank" rel="noreferrer">Grade 6</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-7/" target="_blank" rel="noreferrer">Grade 7</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-8/" target="_blank" rel="noreferrer">Grade 8</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-9/" target="_blank" rel="noreferrer">Grade 9</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-10/" target="_blank" rel="noreferrer">Grade 10</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-11/" target="_blank" rel="noreferrer">Grade 11</a></li>
<li><a href="http://www.suscopts.org/ssc/grade-12/" target="_blank" rel="noreferrer">Grade 12</a></li>
<li><a href="http://www.suscopts.org/ssc/college/" target="_blank" rel="noreferrer">College</a></li>
</ul>
</ul>
                    </div>`,
    ContentCategoryId: "2",
    IsDescriptionHtml: true,
    CreatedAt: new Date(),
    DetailPagePath: "/sunday-school-curriculum",
  },
  {
    Id: "3",
    Title: "Coptic Roaring Lambs",
    Description:
      "Coptic Roaring Lambs (CRL) is a youth ministry activity that meets every other Friday. Please contact any of the High School, Sunday School servant for more details.",
    PageName: "coptic-roaring-lambs",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/coptic-roaring-lambs",
  },
  {
    Id: "4",
    Title: "Arabic Bible Study",
    Description:
      "Meets every Sunday at the church after the Holy Liturgy from 12:15 – 1:15 PM.",
    Image: "/assets/images/ministries/Arabic Bible Study.jpg",
    PageName: "arabic-bible-study",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/arabic-bible-study",
  },
  {
    Id: "5",
    Title: "Blessed Homes Ministry",
    Image: "/assets/images/ministries/Blessed Homes.jpg",
    PageName: "blessed-homes",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/blessed-homes",
  },
  {
    Id: "6",
    Title: "Fellowship Ministry",
    Description: "Meets every Friday @ 7:30 pm at the church.",
    PageName: "fellowship",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/fellowship",
  },
  {
    Id: "7",
    Title: "Board of Deacons",
    Image: "/assets/images/ministries/Board Of Deacons.jpg",
    PageName: "board-of-deacons",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/board-of-deacons",
  },
  {
    Id: "8",
    Title: "St. George Arabic Choir",
    Description: "Meets every Sunday from 1:15 – 2:00 PM.",
    PageName: "st-george-arabic-choir",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/st-george-arabic-choir",
  },
  {
    Id: "9",
    Title: "St. George Youth Choir",
    Description: "Meets Saturdays from 5:00 – 6:00 PM.",
    PageName: "st-george-youth-choir",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/st-george-youth-choir",
  },

  {
    Id: "10",
    Title: "Agaphy Ministry",
    Description:
      "If you would like to help with preparing food and coffee after the Holy Liturgy, please contact Hanan or Mena H.",
    PageName: "agaphy",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/agaphy",
  },
  {
    Id: "11",
    Title: "Holy Bread Ministry",
    Description:
      "Please contact Fady E. or Abouna if you would like to join one of the groups that makes the Holy Korban.",
    PageName: "holy-bread",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/holy-bread",
  },
  {
    Id: "12",
    Title: "Mahragan",
    Image: "/assets/images/ministries/Mahragan.jpg",
    Description: "From June – 2nd weekend of August.",
    PageName: "mahragan",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/mahragan",
  },
  {
    Id: "13",
    Title: "T.S.I Ministry",
    Description: "T.S.I. (Truth Seekers Investigators), Church Drama Team.",
    PageName: "tsi",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/tsi",
  },
  {
    Id: "14",
    Title: "Church Bookstore",
    Description: "Open every Sunday after the Holy Liturgy.",
    PageName: "church-bookstore",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/church-bookstore",
  },
  {
    Id: "15",
    Title: "Egyptian Festival",
    Image: "/assets/images/egyptian festival/Egyptian Festival 2023.jpeg",
    Description: `<a href='https://www.egyptianfestival.us' target='_blank' rel='noreferrer'>www.egyptianfestival.us</a> `,
    IsDescriptionHtml: true,
    PageName: "egyptian-festival",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/egyptian-festival",
  },
  {
    Id: "16",
    Title: "Annual Church Banquet",
    Description:
      "If you would like to help with organizing or if you have ideas for church’s banquet, please contact Ashraf Y.",
    PageName: "annual-church-banquet",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/annual-church-banquet",
  },
  {
    Id: "17",
    Title: "Usher Ministry",
    Description:
      "Every Sunday during the Holy Liturgy. Please contact William G.",
    PageName: "usher",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/usher",
  },
  {
    Id: "18",
    Title: "I.T. Ministry",
    Description:
      "Maintain the church network, sound system, video broadcasting, and liturgy PowerPoint.",
    PageName: "it",
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/it",
  },
] as Content[];
