import { Content } from "./content";

export const HomePageImageCarouselImages = [
  {
    path: "/assets/images/home/St George.jpg",
    altText: "St George Image",
    primaryContentText: "Welcome to the Church Official Website",
    secondaryContentText: "St. George Coptic Orthodox Church, Monee, IL",
  },
  {
    path: "/assets/images/home/Church Interior.jpg",
    altText: "Church Interior Image",
    primaryContentText:
      "Therefore humble yourselves under the mighty hand of God, that He may exalt you in due time",
    secondaryContentText: "1 Peter 5:6",
  },
  {
    path: "/assets/images/home/Church Interior Altar.jpg",
    altText: "Church Interior Altar Image",
    primaryContentText:
      "And it shall come to pass That whoever calls on the name of the Lord Shall be saved",
    secondaryContentText: "Acts 2:21",
  },
];

export const HomePageLatestNews = [
  {
    Id: "1",
    Title: "Egyptian Festival 2023",
    PageName: "egyptian-festival-2023",
    ContentCategoryId: "1",
    CreatedAt: new Date(),
    DetailPagePath: "/latest-news/egyptian-festival-2023",
  },
  {
    Id: "2",
    Title: "Arabic Online Bible Study",
    PageName: "arabic-online-bible-study",
    Description: 'Please download "Microsoft Teams" app',
    ContentCategoryId: "2",
    CreatedAt: new Date(),
    DetailPagePath: "/latest-news/arabic-online-bible-study",
  },
  {
    Id: "3",
    Title: "Covid-19 Church Statement",
    PageName: "covid-19-church-statement",
    ContentCategoryId: "3",
    CreatedAt: new Date(),
    DetailPagePath: "/latest-news/covid-19-church-statement",
  },
] as Content[];
