import BackButton from "../back-button";
import ShareButtons from "../social-media-share-buttons/share-buttons";
import { HeaderDivStyles } from "./styles";

export default function ContentDetailPageHeader() {
  return (
    <div style={HeaderDivStyles}>
      <BackButton />
      <ShareButtons />
    </div>
  );
}
