import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Colors, DrawerWidth, FontFamilies } from "../../../utilities/styles";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  ListItem,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// Custom Row
export const StyledBoxRowForNavbar = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "row",
  width: "-webkit-fill-available",
}));
export const StyledBoxRowForText = styled(StyledBoxRowForNavbar)(
  ({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  })
);
// Custom Toolbar
export const StyledToolbar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  alignItems: "flex-start",
  flexFlow: "wrap",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  backgroundColor: Colors.BlueDark,
  // Override media queries injected by theme.mixins.toolbar
  // Handles Size while Drawer is open
}));

// List Items
export const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:active": {
    backgroundColor: Colors.RedBright,
    transform: "scale(1.3);",
  },
  "&:active,  &:active a, &:active a:visited": {
    color: Colors.WhiteBright,
    transform: "scale(1.3);",
  },
  "&:hover": {
    backgroundColor: Colors.RedBright,
    transform: "scale(1.3);",
  },
  "&:hover, &:hover: a, &:hover a:visited": {
    color: Colors.WhiteBright,
    transform: "scale(1.3);",
  },
  transition: "transform .4s, background-color .4s, color .4s ease-in-out",
}));

// NavLinks
const LinkStyle = `
display: flex;
align-items: center;
justify-content: center;
height: 45px;
width: 100%;
transition: background-color 0.1s, color 0.1s;
transition-timing-function: linear;
cursor: pointer;
border-radius: 20px;
color: ${Colors.GreyRegular};
:visited {
  color: ${Colors.GreyRegular};
}
:visited:hover {
  color: ${Colors.BlueLight};
}
:hover,
:hover:visited {
  color: ${Colors.WhiteBright} !important;
  background-color: ${Colors.BlueLight};
}
:hover p,
:hover:visited p {
  font-size: 1.2em;
}
&.active,
&.active:visited {  background-color: ${Colors.BlueLight};
  color: ${Colors.WhiteBright} !important;
}
&.active p,
&.active:visited p{
  font-size: 1.2em;
}
`;
const LinkTextStyle = `
text-decoration: none;
font-weight: bold;
fontFamily=${FontFamilies.SansProRegular};
`;
const NormalLinkTextSize = `
font-size: 1em;
`;

const SmallLinkTextSize = `
font-size: 0.9em;
`;
const NavTypographyStyle = `
  width: 80%;
  transition: font-size 0.1s;
  transition-timing-function: linear;
  display: flex;
  white-space: nowrap;
`;
export const StyledDummyNavLink = styled("div")(
  LinkStyle + LinkTextStyle + NormalLinkTextSize
);

export const StyledNavLink = styled(NavLink)(
  LinkStyle + LinkTextStyle + NormalLinkTextSize
);
export const StyledNavTypography = styled(Typography)(
  LinkTextStyle + NormalLinkTextSize + NavTypographyStyle
);

export const StyledNavLinkFromMenu = styled(NavLink)(
  LinkStyle + LinkTextStyle + SmallLinkTextSize
);
export const StyledNavTypographyFromMenu = styled(Typography)(
  LinkTextStyle + SmallLinkTextSize + NavTypographyStyle
);

export const StyledAccordionTypography = styled(StyledNavTypography)(LinkStyle);

export const StyledNavLinkDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
}));

export const DrawerContentBoxSxProps = {
  textAlign: "center",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignContent: "baseline",
  paddingTop: "10px",
  flexBasis: "fit-content",
  alignItems: "center",
  paddingLeft: "8px",
  paddingRight: "8px",
  "@media only screen and (max-width: 899px)": {
    fontSize: "14px",
  },
  "@media only screen and (min-width: 900px)": {
    fontSize: "16px",
  },
  gap: "5px",
} as SxProps<Theme>;

export const NavbarDrawer = styled(Drawer)(() => ({
  width: DrawerWidth,
  scrollbarGutter: "stable",
  scrollBehavior: "smooth",
  "& .MuiDrawer-paper": {
    width: DrawerWidth,
    boxSizing: "border-box",
    scrollbarWidth: "none",
  },
}));

export const NavbarAccordionDetails = styled(AccordionDetails)(() => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  pointerEvents: "all",
  gap: "5px",
  paddingLeft: "16px",
  paddingRight: "16px",
}));

export const NavbarAccordionSummary = styled(AccordionSummary)(() => ({
  pointerEvents: "none",
  width: "100%",
  margin: "0",
  padding: "0",
  display: "flex",
  justifyContent: "center",
  height: "45px",
  ".MuiAccordionSummary-content": {
    pointerEvents: "all",
  },
}));

export const NavbarAccordion = styled(Accordion)(() => ({
  width: "100%",
  pointerEvents: "none",
  boxShadow: "none",
}));

export const AppbarBox = styled(Box)(() => ({
  flexGrow: 1,
}));

export const ToolbarTextContainer = styled("div")(() => ({
  textAlign: "center",
  flexGrow: "1",
}));
