import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { FontFamilies } from "../../../utilities/styles";
import { ContentHeaderDividerStyles, ContentHeaderStyles } from "./styles";

interface Props {
  title: String;
}

export default function ContentHeader({ title }: Props) {
  return (
    <div style={ContentHeaderStyles}>
      <Divider sx={ContentHeaderDividerStyles} />

      <Typography
        variant="h5"
        component="div"
        fontWeight="600"
        fontFamily={FontFamilies.SansProRegular}
      >
        {title}
      </Typography>
      <Divider sx={ContentHeaderDividerStyles} />
    </div>
  );
}
