import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
import ContentHeader from "../components/common/content/content-header";
import GeneralContentCard from "../components/common/content/general-content-card";
import {
  StyledContainer,
  StyledStack,
} from "../components/common/content/styles";
import { MinistriesDetails } from "../types/ministries";

export default function Ministries() {
  return (
    <>
      <StyledContainer maxWidth={false}>
      <ContentDetailPageHeader/>
        <ContentHeader title={"Ministries"} />
        <StyledStack>
          {MinistriesDetails.map((ministry, index) => {
            return <GeneralContentCard key={index} content={ministry} />;
          })}
        </StyledStack>
      </StyledContainer>
    </>
  );
}
