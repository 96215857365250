import { experimentalStyled as styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { Colors, FontFamilies } from "../../../utilities/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

export const FooterBox = styled("footer")`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${Colors.GreyDark};
  position: relative;
  bottom: 0;
  width: 100%;
`;

export const FooterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;

export const FooterRow = styled("div")`
  display: flex;
  @media only screen and (max-width: 899px) {
    flex-flow: column;
    gap: 80px;
    align-items: center;
  }
  @media only screen and (min-width: 900px) {
    flex-flow: row;
    gap: 40px;
  }
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
`;

export const FooterColumn = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media only screen and (max-width: 899px) {
    width: 80vw;
    max-width: 400px;
  }
  @media only screen and (min-width: 900px) {
    width: 400px;
  }
`;

export const FooterHeading = styled(Typography)`
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
  font-weight: bold;
  color: ${Colors.BlueLight};
  font-family: ${FontFamilies.SansProRegular};
`;
export const FooterSubHeading = styled(Typography)`
  font-size: 16px;
  color: ${Colors.BlueLight};
  margin-bottom: 15px;
  font-family: ${FontFamilies.SansProRegular};
`;
export const FooterText = styled(Typography)`
  font-size: 14px;
  color: ${Colors.GreyLight};
  font-family: ${FontFamilies.SansProRegular};
  text-align: left;
`;
export const FooterLink = styled(Link)`
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
  color: ${Colors.GreyLight};
  width: fit-content;
  text-decoration: underline;
  font-family: ${FontFamilies.SansProRegular};
  &:hover {
    color: ${Colors.BlueDark};
    transition: 200ms ease-in;
  }
`;

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1A2027",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


export const SocialMediaBoxContainer = styled(Box)(() => ({
  flexFlow: "row",
  justifyContent: "space-between",
  display: "flex",
  margin: "0px",
  padding: "0px",
  padddingLeft: "0px",
  paddingRight: "0px",
  width: "182px",
}));