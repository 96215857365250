export interface Content {
    Id: string;
    Title: string;
    ContentCategoryId: string;
    PageName:string;
    Description?: string;
    IsDescriptionHtml?: boolean;
    Image?: string;
    CreatedAt: Date;
    DetailPagePath?: string;
  }
  export interface ContentAction{
    Label: string;
    ActionLink: string;
    IsExternal: boolean;
  }
  
  export interface ContentCategory {
    [key: string]: string;
  }
  export const ContentCategories = [];

  export const TruncateLength = 80;
