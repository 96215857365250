import Card from "@mui/material/Card";
import { experimentalStyled as styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { Colors, FontFamilies } from "../../../utilities/styles";
import { Container, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

export const maxContainerWidth = "1240px";
export const containerWidth = "90vw";
export const minContainerWidth = "320px";

export const StyledCard = styled(Card)(() => ({
  height: "100%",
  marginLeft: "0px",
  "@media only screen and (max-width: 899px)": {
    width: "80%",
  },
  "@media only screen and (min-width: 900px)": {
    width: "calc(25% - 2px)",
    marginLeft: "4.16%",
    marginRight: "4.16%",
  },
}));

export const StyledNavLink = styled(NavLink)(() => ({
  border: `1px solid ${Colors.BlueLight};`,
  color: Colors.BlueLight,
  backgroundColor: "white",
  padding: "5px 10px",
  textTransform: "uppercase",
  fontSize: "14px",
  textDecoration: "none",
  fontWeight: "500",
  "&:hover": {
    color: "white",
    backgroundColor: Colors.BlueLight,
    transition: "200ms ease-in",
  },
}));

export const CardContentStyles = {
  "@media only screen and (max-width: 899px)": {
    height: "150px",
  },
  "@media only screen and (min-width: 900px)": {
    height: "150px",
  },
};
export const CardActionsStyles = {
  justifyContent: "center",
  "@media only screen and (max-width: 899px)": {
    height: "100px",
  },
  "@media only screen and (min-width: 900px)": {
    height: "50px",
  },
};

export const ContentHeaderStyles = {
  display: "flex",
  justifyContent: "center",
  flexFlow: "row",
  alignItems: "center",
  gap: "20px",
  marginBottom: "30px",
};
export const ContentHeaderDividerStyles = {
  flexGrow: "1",
};

export const ContentTitleDividerStyles = {
  width: "calc(100% + 32px)",
  left: "-16px",
  position: "relative"
};

export const StyledContainer = styled(Container)(() => ({
  minWidth: minContainerWidth,
  width: containerWidth,
  maxWidth: maxContainerWidth,
  boxShadow: "inset 0 0 1px #000000",
  padding: "20px"
}));

export const StyledStack = styled(Stack)(() => ({
  gap: "50px 0",
  flexFlow: "wrap",
  "@media only screen and (max-width: 899px)": {
   flexDirection: "column",
   alignContent: "center",

  },
  "@media only screen and (min-width: 900px)": {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  marginBottom: "25px"
}))

export const SectionHeading = styled(Typography)`
  color: #fff;
  margin-bottom: 15px;
  font-weight: bold;
  color: ${Colors.BlueLight};
  font-family: ${FontFamilies.SansProRegular};
`;

export const HeaderDivStyles = {
  display: "flex",
  flexFlow: "row",
  justifyContent: "space-between",
  alignItems: "center",
};

export const SocialMediaHeaderDivStyles = {
  display: "flex",
  flexFlow: "row",
  gap: "5px",
};
