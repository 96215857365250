import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
import ContentHeader from "../components/common/content/content-header";
import { StyledContainer } from "../components/common/content/styles";
import { Typography } from "@mui/material";

export default function ContactUs() {
  return (
    <>
      <StyledContainer maxWidth={false}>
        <ContentDetailPageHeader />
        <ContentHeader title={"Contact Us"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign={"center"}
        >
          Viber link to come, please check back later.
        </Typography>
      </StyledContainer>
    </>
  );
}
