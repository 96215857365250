import MenuIcon from "@mui/icons-material/Menu";
import { StyledIconButton } from "./styles";

interface Props {
  onClick: Function;
}

export default function HamburgerMenu({ onClick }: Props) {
  return (
    <>
      <StyledIconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{marginTop: "auto", marginBottom: "auto"  }}
        onClick={() => onClick()}
      >
        <MenuIcon />
      </StyledIconButton>
    </>
  );
}
