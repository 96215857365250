import createTheme from "@mui/material/styles/createTheme";
import React, { ReactNode } from "react";

const theme = createTheme({
  breakpoints: {

  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#202020",
              color: "#fff",
            }),
        }),
      },
    },
  },
});
const ThemeContext = React.createContext(theme);

interface Props {
  children: ReactNode;
}
export default function ThemeContextProvider({ children }: Props) {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
}
