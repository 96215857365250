import Typography from "@mui/material/Typography";
import HamburgerMenu from "../../utilities/hamburgerMenu";
import { useEffect, useState } from "react";
import StyledDrawer from "./drawer";
import { FontFamilies } from "../../../utilities/styles";
import {
  StyledToolbar,
  StyledBoxRowForNavbar,
  ToolbarTextContainer,
} from "./styles";

export default function StyledNavbar() {
  const [open, setDrawerState] = useState(false);
  const [navbarContainerHeight, setNavbarContainerHeight] = useState(0);
  const checkCloseDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerState(false);
    };

  const openDrawer = () => {
    setDrawerState(true);
  };
  const closeDrawer = () => {
    setDrawerState(false);
  };

  const updateNavbarContainerHeight = () => {
    const newHeight = document.getElementById(
      "NavbarContentContainer"
    )?.offsetHeight;
    if (newHeight && newHeight !== navbarContainerHeight)
      setNavbarContainerHeight(newHeight);
  };
  window.addEventListener("resize", () => updateNavbarContainerHeight());

  useEffect(() => {
    updateNavbarContainerHeight();
  });
  return (
    <div id="NavbarContainer" style={{ height: navbarContainerHeight + "px" }}>
      <StyledToolbar id="NavbarContentContainer" open={open}>
        <StyledBoxRowForNavbar>
          {open === false ? (
            <HamburgerMenu onClick={openDrawer} />
          ) : (
            <HamburgerMenu onClick={() => {}} />
          )}
          <ToolbarTextContainer>
            <Typography
              variant="h6"
              component="div"
              fontFamily={FontFamilies.SansProRegular}
              fontWeight="600"
            >
              St. George Coptic Church Chicago
              <Typography
                variant="body1"
                noWrap
                component="div"
                fontFamily={FontFamilies.SansProRegular}
              >
                Monee, IL
              </Typography>
            </Typography>
          </ToolbarTextContainer>
          {/* <SearchBar /> */}
        </StyledBoxRowForNavbar>
      </StyledToolbar>
      {/* Sidebar Navigation can be found here */}
      <StyledDrawer
        open={open}
        checkCloseDrawer={checkCloseDrawer}
        closeDrawer={closeDrawer}
      />
    </div>
  );
}
