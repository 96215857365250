import FacebookIcon from "@mui/icons-material/Facebook";
import Link from "@mui/material/Link";
import { Colors } from "../../../../utilities/styles";
export default function FacebookShareButton() {
  return (
    <>

        <Link
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
          className="fb-xfbml-parse-ignore"
          sx={{ "&:hover": { color: Colors.BlueFacebook } }}
          target="_blank"
          rel="noopener"
          aria-label="Facebook Share Button"
        >
          <FacebookIcon />
        </Link>
    </>
  );
}
