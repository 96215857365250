import { experimentalStyled as styled } from "@mui/material/styles";
import { minContainerWidth, containerWidth, maxContainerWidth } from "../../common/content/styles";

export const ImageCaraouselTextContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  flexFlow: "column",
  alignContent: "center",
  position: "relative",
  height: "100px",
  color: "white",
  backgroundColor: "black",
  minWidth: minContainerWidth,
  width: containerWidth,
  maxWidth: maxContainerWidth,
  animationTimingFunction: "linear",
  opacity: "0.7",
  marginTop: "-100px",
  animation: "fadeUpIn 1.5s",
  "@keyframes fadeUpIn": {
    "0%": { opacity: "0", marginTop: "-75px", paddingTop: "25px" },
    "100%": { opacity: "0.7", marginTop: "-100px", paddingTop: "0px" },
  },
  ".MuiTypography-root": {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
}));

export const ImageCarouselContentContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  flexFlow: "wrap",
}));

export const ImageCarouselContentImage = styled("img")(() => ({
  width: "100vw",
  height: "25vw",
  minHeight: "125px",
}));


