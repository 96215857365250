import Typography from "@mui/material/Typography";
import { useState } from "react";
import { FontFamilies } from "../../../utilities/styles";
import { ImageCaraouselTextContainer } from "./styles";

interface Props {
  primaryText: string;
  secondaryText: string;
}

export const ImageCaraouselSlideUpText = ({
  primaryText,
  secondaryText,
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const bigVariant = windowWidth >= 1200 ? "h5" : "body2";
  const smallVariant = windowWidth >= 1200 ? "body1" : "body2";

  window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

  return (
    <ImageCaraouselTextContainer>
      <Typography
        variant={bigVariant}
        component="div"
        fontFamily={FontFamilies.SansProRegular}
        fontWeight="bold"
        width="100%"
      >
        {primaryText}
      </Typography>
      <br />
      <Typography
        variant={smallVariant}
        component="div"
        width="100%"
        fontFamily={FontFamilies.SansProRegular}
      >
        {secondaryText}
      </Typography>
    </ImageCaraouselTextContainer>
  );
};
