import { ImageCarousel } from "../components/pages/home/imageCaraousel";
import ContentHeader from "../components/common/content/content-header";
import {
  StyledContainer,
} from "../components/common/content/styles";
import { Typography } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Colors } from "../utilities/styles";
import { ActionLink } from "./styles";

export default function Home() {
  return (
    <>
      <ImageCarousel />
      <StyledContainer maxWidth={false}>
        <ContentHeader title={"Live Stream"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          marginBottom="25px"
        >
          To see our Live Stream, please visit our YouTube page &nbsp;
          <ActionLink
            aria-label="Youtube Link"
            sx={{ "&:hover": { color: Colors.RedYoutube } }}
            href="https://www.youtube.com/@stgeorgecocchicagoil506/streams"
            rel="noopener"
            target="_blank"
          >
            Here <YouTubeIcon />
          </ActionLink>
        </Typography>
         <ContentHeader title={"This Month"} /> 
       <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold">
          <img width="100%" src={process.env.PUBLIC_URL + "/assets/images/egyptian festival/Egyptian Festival 2024.jpeg"} alt="Egyptian Festival 2024" />
        </Typography> 
        {/* <ContentHeader title={"This Month"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          marginBottom="25px"
        >
          <u>Holy Week Journey</u> <br /><br />
          <img width="100%" src={process.env.PUBLIC_URL + "/assets/images/home/Holy Week Journey.jpeg"} alt="Holy Week Journey 2024" />

        </Typography>

        <ContentHeader title={"Holy Pascha Schedule"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          marginBottom="25px"
        >
          <img width="100%" src={process.env.PUBLIC_URL + "/assets/images/home/Holy Pascha 2024.jpg"} alt="Holy Pascha Shcedule 2024" />

        </Typography> */}
        {/* <StyledStack>
          {HomePageLatestNews.map((latestNewsContent, index) => {
            return (
              <GeneralContentCard key={index} content={latestNewsContent} />
            );
          })}
        </StyledStack> */}

        <ContentHeader title={"Sermons"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          marginBottom="25px"
        >
          To see our sermons, please visit our YouTube page &nbsp;
          <ActionLink
            aria-label="Youtube Link"
            sx={{ "&:hover": { color: Colors.RedYoutube } }}
            href="https://www.youtube.com/channel/UChEJfGq4Vynw3vxxyyTXshg"
            rel="noopener"
            target="_blank"
          >
            Here <YouTubeIcon />
          </ActionLink>
        </Typography>
      </StyledContainer>
    </>
  );
}
