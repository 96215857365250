import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {
  FooterBox,
  FooterContainer,
  FooterRow,
  FooterColumn,
  FooterLink,
  FooterHeading,
  FooterSubHeading,
  FooterText,
  SocialMediaBoxContainer,
} from "./styles";
import { Colors } from "../../../utilities/styles";

const Footer = () => {
  return (
    <FooterBox id="FooterContainer">
      <FooterContainer>
        <FooterRow>
          <FooterColumn>
            <FooterHeading aria-label="Footer Contact">COTNACT</FooterHeading>
            <div>
              <FooterSubHeading aria-label="St. George Church">
                St. George Church
              </FooterSubHeading>
              <FooterText aria-label="St. George Church Address">
                <b style={{ fontWeight: "bolder" }}>Address - </b>
                <FooterLink href="https://www.google.com/maps/place/St.+George+Coptic+Orthodox+Church/@41.3976162,-87.7228244,17z/data=!3m1!4b1!4m5!3m4!1s0x880e0fbf0a18d891:0xcf638fbdb831ab0e!8m2!3d41.3977452!4d-87.7228287">
                  4601 W. Pauling Rd., Monee, IL 60449
                </FooterLink>
              </FooterText>
              <FooterText aria-label="Contact Phone Number">
                <b style={{ fontWeight: "bolder" }}>Phone - </b>
                <FooterLink href="tel:708-534-6003">708-534-6003</FooterLink>
              </FooterText>
              <FooterText aria-label="Contact Email">
                <b style={{ fontWeight: "bolder" }}>Email - </b>
                <FooterLink href="mailto:abouna(@)stgeorgechicago.org">
                  abouna@stgeorgechicago.org
                </FooterLink>
              </FooterText>
            </div>
          </FooterColumn>
          <FooterColumn>
            <FooterHeading aria-label="Footer Our Mission">
              OUR MISSION
            </FooterHeading>
            <FooterText aria-label="Our Mission" style={{textAlign: "center"}}>
              "For God so loved the world that He gave His only begotten Son,
              that whoever believes in Him should not perish but have
              everlasting life." John 3:16
            </FooterText>
          </FooterColumn>
          <FooterColumn>
            <FooterHeading aria-label="Social Meida">
              SOCIAL MEDIA
            </FooterHeading>
            <SocialMediaBoxContainer>
              <FooterLink
                aria-label="Twitter Link"
                sx={{ "&:hover": { color: Colors.BlueTwitter } }}
                href="https://twitter.com/stgeorgechicago"
                rel="noopener"
                target="_blank"
              >
                <TwitterIcon />
              </FooterLink>
              <FooterLink
                aria-label="Facebook Link"
                sx={{ "&:hover": { color: Colors.BlueFacebook } }}
                target="_blank"
                href="https://www.facebook.com/stgeorgechicagocopts"
                rel="noopener"
              >
                <FacebookIcon />
              </FooterLink>
              <FooterLink
                aria-label="Youtube Link"
                sx={{ "&:hover": { color: Colors.RedYoutube } }}
                href="https://www.youtube.com/channel/UChEJfGq4Vynw3vxxyyTXshg"
                rel="noopener"
                target="_blank"
              >
                <YouTubeIcon />
              </FooterLink>
              <FooterLink
                aria-label="Youtube Link"
                sx={{ "&:hover": { color: Colors.OrangeSoundcloud } }}
                href="https://soundcloud.com"
                rel="noopener"
                target="_blank"
              >
                <LibraryMusicIcon />
              </FooterLink>
            </SocialMediaBoxContainer>
          </FooterColumn>
        </FooterRow>
      </FooterContainer>
    </FooterBox>
  );
};
export default Footer;
