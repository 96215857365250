import { experimentalStyled as styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Colors, FontFamilies } from "../utilities/styles";


export const ActionLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  height: fit-content;
  display: inline-flex;
  gap: 5px;
  text-decoration: none;
  color: black;
  text-decoration: none;
  font-family: ${FontFamilies.SansProRegular};
  &:hover {
    color: ${Colors.BlueDark};
    transition: 200ms ease-in;
  }
  marginTop: auto;
`;
