import { StyledIconButton } from "../utilities/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function BackButton() {
  return (
    <StyledIconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={() => window.history.back()}
    >
      <ArrowBackIcon />
    </StyledIconButton>
  );
}
