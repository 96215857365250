import { Outlet, ScrollRestoration } from "react-router-dom";
import "./App.css";
import Footer from "./components/layout/footer/footer";
import StyledNavbar from "./components/layout/navbar/navbar";

function App() {
  return (
    <>
      <StyledNavbar />
      <div style={{ minHeight: "100vh" }}>
        <ScrollRestoration/>
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default App;
