import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
import ContentHeader from "../components/common/content/content-header";
import { StyledContainer } from "../components/common/content/styles";

export default function Resources() {
  return (
    <>
      <StyledContainer maxWidth={false}>
        <ContentDetailPageHeader />
        <ContentHeader title={"Coptic Hymns"} />
        <img
          width="100%"
          src={process.env.PUBLIC_URL + "/assets/images/resources/Coptic Hymns.jpg"}
          alt={"Coptic Hymns"}
        />
      </StyledContainer>
    </>
  );
}
