import ContentHeader from "../components/common/content/content-header";
import { StyledContainer } from "../components/common/content/styles";
import { Typography } from "@mui/material";
import { ScrollRestoration } from "react-router-dom";
import Footer from "../components/layout/footer/footer";
import StyledNavbar from "../components/layout/navbar/navbar";
import BackButton from "../components/common/back-button";

export default function NotFound() {
  return (
    <>
      <StyledNavbar />
      <div style={{ minHeight: "100vh" }}>
        <StyledContainer maxWidth={false}>
          <BackButton />
          <ContentHeader title={"Not Found"} />
          <Typography
            color="text.primary"
            variant="body1"
            fontWeight="bold"
            textAlign={"center"}
          >
            Sorry, the page you were looking for was not found
          </Typography>
          <ScrollRestoration />
        </StyledContainer>
      </div>
      <Footer />
    </>
  );
}
