import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
import ContentHeader from "../components/common/content/content-header";
import { StyledContainer } from "../components/common/content/styles";
import { Typography } from "@mui/material";

export default function OurPriest() {
  return (
    <>
      <StyledContainer maxWidth={false}>
        <ContentDetailPageHeader />
        <ContentHeader title={"Our Priest"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign={"center"}
        >
          Fr. Samuel Azmy
          <br />
          abouna (@) stgeorgechicago.org
        </Typography>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            width="50%"
            src={
              process.env.PUBLIC_URL +
              "/assets/images/our priest/Our Priest.jpg"
            }
            alt={"Our Priest"}
          />
        </div>
      </StyledContainer>
    </>
  );
}
