import { Typography } from "@mui/material";
import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
import ContentHeader from "../components/common/content/content-header";
import {
  SectionHeading,
  StyledContainer,
} from "../components/common/content/styles";

export default function Giving() {
  return (
    <>
      <StyledContainer maxWidth={false}>
      <ContentDetailPageHeader/>

        <ContentHeader title={"Giving"} />
        <SectionHeading variant="h5" textAlign={"center"}>
          Give Online
        </SectionHeading>
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign={"center"}
        >
          Scan the QR Code
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            width="50%"
            src={process.env.PUBLIC_URL + "/assets/images/Giving-QR-Code.jpg"}
            alt="Giving QR-Code"
          />
        </div>
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign={"center"}
        >
          OR
          <br />
          <br />
          <a
            href="https://midwestcopts.com//Giving/17CB525B2EC00585"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            Click Here
          </a>
          <br />
          <br />
          OR
          <br />
          <br />
          Text 'stgeorge' to &nbsp;
          <a href="tel:708-726-3900" style={{ textDecoration: "none" }}>
            708-726-3900
          </a>
        </Typography>
        <br />
        <br />
        <SectionHeading variant="h5" textAlign={"center"}>
          Mail
        </SectionHeading>
        <Typography color="text.primary" variant="body1" textAlign={"center"}>
          <b>
            <u>Please mail your donation to:</u>
          </b>
          <br />
          P.O. Box 250
          <br />
          Monee, IL 60449
        </Typography>
        <br />
        <br />
        <SectionHeading variant="h5" textAlign={"center"}>
          In Person at the Church
        </SectionHeading>
        <Typography color="text.primary" variant="body1" textAlign={"center"}>
          Offering boxes are available at the entrance of the church
        </Typography>
      </StyledContainer>
    </>
  );
}
