import { Content } from "../types/content";
import { HomePageLatestNews } from "../types/home";
import { MinistriesDetails } from "../types/ministries";

export const GetContentById = (
  contentId: string,
  contentCategoryId: string
) => {
  let contentArray = Array<Content>();
  switch (contentCategoryId) {
    case "1":
      contentArray = HomePageLatestNews;
      break;
    case "2":
      contentArray = MinistriesDetails;
      break;

    default:
      break;
  }

  return contentArray.find((content) => content.Id === contentId);
};
