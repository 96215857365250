import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
import ContentHeader from "../components/common/content/content-header";
import { StyledContainer } from "../components/common/content/styles";
import { Typography } from "@mui/material";

export default function Calendar() {
  return (
    <>
      <StyledContainer maxWidth={false}>
        <ContentDetailPageHeader />
        <ContentHeader title={"Calendar"} />
        <Typography
          color="text.primary"
          variant="body1"
          fontWeight="bold"
          textAlign={"center"}
        >
          Regular Liturgies: <br />
          Sunday: 8:00 AM - 12:00 PM
        </Typography>
        <br />
        <br />
        <iframe
          title="Calendar"
          style={{ border: "0px currentColor;" }}
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&src=c3RnZW9yZ2VpbEBnbWFpbC5jb20&color=%234285F4"
          width="100%"
          height="600"
        ></iframe>
      </StyledContainer>
    </>
  );
}
