import DrawerContent from "./drawerContent";
import { NavbarDrawer } from "./styles";

interface Props {
  open: boolean;
  checkCloseDrawer: Function;
  closeDrawer: Function;
}

export default function StyledDrawer({ open, checkCloseDrawer, closeDrawer }: Props) {
  return (
    <NavbarDrawer
      variant="temporary"
      anchor="left"
      open={open}
      onClose={checkCloseDrawer()}
    >
      <DrawerContent
        onClick={() => closeDrawer()}
        onKeyDown={() => checkCloseDrawer()}
      />
    </NavbarDrawer>
  );
}
