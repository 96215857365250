import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeContextProvider from "./context/theme-context";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import Giving from "./pages/giving";
import ContentDetail from "./pages/content-detail";
import NotFound from "./pages/not-found";
import Ministries from "./pages/ministries";
import { MinistriesDetails } from "./types/ministries";
import Resources from "./pages/resources";
import OurPriest from "./pages/our-priest";
import ContactUs from "./pages/contact-us";
import Calendar from "./pages/calendar";
import Home from "./pages/home";
import { HomePageLatestNews } from "./types/home";

const dynamicallyGeneratedDetailPages = HomePageLatestNews.map(
  (content, index) => {
    return {
      path: content.DetailPagePath,
      element: (
        <ContentDetail
          key={index}
          contentId={content.Id}
          contentCategoryId={content.ContentCategoryId}
        ></ContentDetail>
      ),
    } as RouteObject;
  }
);

const dynamicallyMinistriesDetailPages = MinistriesDetails.map(
  (content, index) => {
    return {
      path: content.DetailPagePath,
      element: (
        <ContentDetail
          key={index}
          contentId={content.Id}
          contentCategoryId={content.ContentCategoryId}
        ></ContentDetail>
      ),
    } as RouteObject;
  }
);

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        element: <NotFound />,
        path: "/not-found",
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/giving",
        element: <Giving />,
      },
      {
        path: "/ministries",
        element: <Ministries />,
      },
      {
        path:"/resources",
        element: <Resources/>
      },
      {
        path:"/our-priest",
        element: <OurPriest/>
      },
      {
        path:"/contact-us",
        element: <ContactUs/>
      },
      {
        path:"/calendar",
        element: <Calendar/>
      },
      ...dynamicallyGeneratedDetailPages,
      ...dynamicallyMinistriesDetailPages

    ],
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <RouterProvider router={router} />
    </ThemeContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
