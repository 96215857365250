import { Typography } from "@mui/material";
import { SocialMediaHeaderDivStyles } from "../content/styles";
import FacebookShareButton from "./buttons/facebook-share-button";
import TwitterShareButton from "./buttons/twitter-share-button";

export default function ShareButtons() {
  return (
    <div style={SocialMediaHeaderDivStyles}>
      <Typography color="text.primary" variant="body1" fontWeight="bold">
        Share
      </Typography>
      <TwitterShareButton />
      <FacebookShareButton />
    </div>
  );
}
