import Carousel from "react-material-ui-carousel";
import { HomePageImageCarouselImages } from "../../../types/home";
import { ImageCaraouselSlideUpText } from "./imageCaraousel-SlideUpText";
import { ImageCarouselContentContainer, ImageCarouselContentImage } from "./styles";

export function ImageCarousel() {
  return (
    <Carousel
      animation="fade"
      swipe={true}
      indicators={false}
      // autoPlay={false} // Uncomment/Comment for Live/Test
      height="25vw"
      stopAutoPlayOnHover={true}
      interval={5000}
      strictIndexing={true}
      sx={{minHeight: "125px"}}
    >
      {HomePageImageCarouselImages.map((image, i) => (
        <ImageCarouselContentContainer key={i}>
          <ImageCarouselContentImage
            src={process.env.PUBLIC_URL + image.path}
            alt={image.altText}
          />
          <ImageCaraouselSlideUpText
            primaryText={image.primaryContentText}
            secondaryText={image.secondaryContentText}
          />
        </ImageCarouselContentContainer>
      ))}
    </Carousel>
  );
}