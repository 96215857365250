import ContentHeader from "../components/common/content/content-header";
import { StyledContainer } from "../components/common/content/styles";
import { Typography } from "@mui/material";
import { GetContentById } from "../utilities/content";
import ContentDetailPageHeader from "../components/common/content/content-detail-page-header";
interface Props {
  contentId: string;
  contentCategoryId: string;
}

export default function ContentDetail({ contentId, contentCategoryId }: Props) {
  const content = GetContentById(contentId, contentCategoryId);
  return (
    <>
      {content && (
        <StyledContainer maxWidth={false}>
          <ContentDetailPageHeader/>
          <ContentHeader title={content.Title} />
          {content.Description &&
            (content.IsDescriptionHtml ? (
              <Typography
                color="text.primary"
                variant="body1"
                dangerouslySetInnerHTML={{ __html: content.Description }}
              ></Typography>
            ) : (
              <Typography
                color="text.primary"
                variant="body1"
                fontWeight="bold"
                textAlign={"center"}
              >
                {content.Description}
              </Typography>
            ))}
            <br/>
          {content.Image && (
            <img
              width="100%"
              src={process.env.PUBLIC_URL + content.Image}
              alt={content.Title}
            />
          )}
        </StyledContainer>
      )}
    </>
  );
}
