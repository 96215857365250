import { Content, TruncateLength } from "../../../types/content";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import {
  CardActionsStyles,
  CardContentStyles,
  ContentTitleDividerStyles,
  StyledCard,
  StyledNavLink,
} from "./styles";

interface Props {
  content: Content;
}
export default function GeneralContentCard({ content }: Props) {

  return (
    <StyledCard variant="outlined">
      <CardContent sx={CardContentStyles}>
        <Typography
          color="text.primary"
          variant="body1"
          gutterBottom
          fontWeight="bold"
          textAlign={"center"}
          marginBottom={"15px"}
        >
          {content.Title}
        </Typography>
        <Divider sx={ContentTitleDividerStyles} />

        {content.Description && !content.IsDescriptionHtml && (
          <Typography variant="body2" color="text.secondary" marginTop={"15px"}>
            {content.Description && content.Description.length > TruncateLength
              ? content.Description.substring(0, TruncateLength) + "..."
              : content.Description}
          </Typography>
        )}
      </CardContent>
      {content.DetailPagePath && (
        <>
          <Divider />

          <CardActions sx={CardActionsStyles}>
            <StyledNavLink to={content.DetailPagePath}>READ MORE</StyledNavLink>
          </CardActions>
        </>
      )}
    </StyledCard>
  );
}
