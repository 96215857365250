import TwitterIcon from "@mui/icons-material/Twitter";
import Link from "@mui/material/Link";
import { Colors } from "../../../../utilities/styles";
export default function TwitterShareButton() {
  <script async src="https://platform.twitter.com/widgets.js"></script>;
  return (
    <Link
      href="https://twitter.com/intent/tweet?button_hashtag=link&ref_src=twsrc%5Etfw"
      sx={{ "&:hover": { color: Colors.BlueTwitter } }}
      target="_blank"
      rel="noopener"
      aria-label="Twitter Share Button"

    >
      <TwitterIcon />
    </Link>
  );
}
