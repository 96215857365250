export enum Colors {
  BlueDark = "#1b5896",
  BlueLight = "#6e95bd",
  BlueFacebook = "#2374e1",
  BlueTwitter = "#1da1f2",
  RedBright = "#dd1212",
  RedYoutube = "#ff0000",
  WhiteBright = "#f8f8f8",
  GreyLight = "#d3d3d3",
  GreyRegular = "#636a7d",
  GreyDark = "#3f4549",
  OrangeSoundcloud = "#ff5500",
}

export enum FontFamilies {
  SansProRegular = "'Source Sans Pro', sans-serif",
}

export const DrawerWidth = 200;
