import { Box } from "@mui/material";
import React from "react";
import {
  DrawerContentBoxSxProps,
  StyledNavLink,
  StyledNavLinkDivider,
  StyledNavTypography,
} from "./styles";

interface Props {
  onClick: Function;
  onKeyDown: Function;
}

export default function DrawerContent({ onClick, onKeyDown }: Props) {
  return (
    <Box
      sx={DrawerContentBoxSxProps}
      role="presentation"
      onKeyDown={!onKeyDown ? () => {} : () => onKeyDown()}
    >
      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/"
      >
        <StyledNavTypography>Home</StyledNavTypography>
      </StyledNavLink>
      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/giving"
      >
        <StyledNavTypography>Giving</StyledNavTypography>
      </StyledNavLink>

      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/calendar"
      >
        <StyledNavTypography>Calendar</StyledNavTypography>
      </StyledNavLink>
      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/ministries"
      >
        <StyledNavTypography>Ministries</StyledNavTypography>
      </StyledNavLink>
      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/resources"
      >
        <StyledNavTypography>Resources</StyledNavTypography>
      </StyledNavLink>
      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/our-priest"
      >
        <StyledNavTypography>Our Priest</StyledNavTypography>
      </StyledNavLink>
      <StyledNavLinkDivider />
      <StyledNavLink
        className={({ isActive, isPending }) =>
          isActive ? "active" : isPending ? "pending" : ""
        }
        onClick={() => onClick()}
        to="/contact-us"
      >
        <StyledNavTypography>Contact Us</StyledNavTypography>
      </StyledNavLink>
      <StyledNavLinkDivider />
    </Box>
  );
}
